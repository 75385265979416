export default function (dataAnswers, viewLevel, entryKey = null) {
  const list = {};

  //console.log('dataAnswers', dataAnswers);
  dataAnswers
    ?.filter(a => a.type === viewLevel)
    ?.map(a => {
      a.entries?.map(e => {
        const key = entryKey ? e[entryKey] : e.nameEn || e.order;

        list[key] = [...(list[key] || []), e];
      });
    });

  const resultList = {};
  Object.keys(list)?.map(key => {
    const answers = list[key];
    if (answers.length < 2 && viewLevel !== 'question') {
      return;
    }
    const maxPoints = answers[0].maxPoints?.map((_, i) =>
      answers.reduce((a, b) => {
        return a + b.maxPoints[i] + 1;
      }, -1)
    );
    const points = answers[0].points?.map((_, i) =>
      answers.reduce((a, b) => {
        if (b.points[i] === 0) {
          return a;
        }
        return a + b.points[i] + 1;
      }, -1)
    );

    const percentage = points.map((p, i) => {
      const _p = (p / maxPoints[i]) * 100;

      //console.log('p', p, 'maxPoints[i]', maxPoints[i] + answers.length, '_p', _p, 'answers', answers);
      if (isNaN(_p)) {
        return 0;
      }
      return Math.max(0, _p);
    });
    const singleMax = answers.map(a => a.singleMax).reduce((a, b) => a + b, 0);
    const values = answers
      .map(a => a.values)
      .flat()
      .map(v => parseInt(v));
    const combined = {
      ...answers[0],
      values: answers.map(a => a.values).flat(),
      valuesByStage: [...answers.map(a => a.values)],
      maxPointsByStage: [...answers.map(a => a.singleMax)],
      maxPoints,
      points,
      average: [values.reduce((a, b) => a + b, 0) / (values.length / answers.length)],
      percentage: percentage,
      answersCount: answers.length,
      singleMax: singleMax,
    };
    resultList[key] = combined;
  });
  //console.log('resultList', resultList);
  return [
    {
      ...dataAnswers?.[0],
      entries: Object.values(resultList),
      stageEn: 'Combined',
      stageFi: 'Yhdistelmä',
      stageSv: 'Kombinerad',
      type: viewLevel,
    },
  ];
}
