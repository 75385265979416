import Vue from "vue";
import Vuex from "vuex";
import apiCollection from "./api/api.js";
import i18n from "./i18n.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    mainMenuOpen: false,
    notificationObject: {
      color: "alert",
      message: "",
      timeOut: 4000,
      showButton: false,
      buttonText: "",
      callback: () => {},
    },
    loadingOverlay: false,
    bottomNavigation: false,
    entries: [],
    sites: [],
    equipments: [],
    questionnaireTypes: ["360", "CDD", "Motivation", "Personality", "Board", "Other"],
    selectedQuestionnaireType: null,
    testers: [
      /*'@hubble.fi',*/
      /*'anssi.saxelin@humandimension.fi', '@signium.se'*/
    ],
    areaColors: {
      "DO RIGHT": { header: "#003789", cell: "#4c73ac" },
      "DO FIRST": { header: "#e31f40", cell: "#d27373" },
      "DO MOST": { header: "#f1ad4a", cell: "#fff796" },
      "DO BEST": { header: "#449618", cell: "#7cb55d" },
      "DO WISELY": { header: "#f16c33", cell: "#ffb74c" },

      "GÖR RÄTT": { header: "#003789", cell: "#4c73ac" },
      "GÖR FÖRST": { header: "#e31f40", cell: "#d27373" },
      "GÖR MEST": { header: "#f1ad4a", cell: "#fff796" },
      "GÖR BÄST": { header: "#449618", cell: "#7cb55d" },
      "GÖR KLOKT": { header: "#f16c33", cell: "#ffb74c" },

      "PURPOSE-DRIVEN": { header: "#3b8e80", cell: "#9ec5bf" },
      "PERFORMANCE-DRIVEN": { header: "#e31f40", cell: "#ef97a8" },
      "ENGAGEMENT-DRIVEN": { header: "#f1ad4a", cell: "#f8c799" },
      "PROCESS-DRIVEN": { header: "#2856ad", cell: "#7693c7" },
      "EXTERNALLY DRIVEN": { header: "#f16c33", cell: "#fcb8aa" },

      ["Purpose-driven leader".toUpperCase()]: { header: "#3b8e80", cell: "#9ec5bf" },
      ["Performance dimension".toUpperCase()]: { header: "#e31f40", cell: "#ef97a8" },
      ["Engagement-driven leader".toUpperCase()]: { header: "#f1ad4a", cell: "#f8c799" },
      ["Process-driven leader".toUpperCase()]: { header: "#2856ad", cell: "#7693c7" },
      ["External-driven leader".toUpperCase()]: { header: "#f16c33", cell: "#fcb8aa" },

      ["Purpose-driven".toUpperCase()]: { header: "#3b8e80", cell: "#9ec5bf" },
      ["Performance-driven".toUpperCase()]: { header: "#e31f40", cell: "#ef97a8" },
      ["Engagement-driven".toUpperCase()]: { header: "#f1ad4a", cell: "#f8c799" },
      ["Process-driven".toUpperCase()]: { header: "#2856ad", cell: "#7693c7" },
      ["External-driven".toUpperCase()]: { header: "#f16c33", cell: "#fcb8aa" },
      ["Externally driven".toUpperCase()]: { header: "#f16c33", cell: "#fcb8aa" },
      ["Innovation-driven".toUpperCase()]: { header: "#3b8e80", cell: "#9ec5bf" },
      ["Curiosity-driven".toUpperCase()]: { header: "#3b8e80", cell: "#9ec5bf" },

      AGREEABLENESS: { header: "#3b8e80", cell: "#9ec5bf" },
      CONCIENTIOUSNESS: { header: "#e31f40", cell: "#ef97a8" },
      EXTRAVERSION: { header: "#f1ad4a", cell: "#f8c799" },
      "EMOTIONAL STABILITY": { header: "#2856ad", cell: "#7693c7" },
      "OPENNESS TO CHANGE": { header: "#f16c33", cell: "#fcb8aa" },

      ["1. Ägardirektiv och Strategi".toUpperCase()]: { header: "#3b8e80", cell: "#9ec5bf" },
      ["2. Bolagsstyrning".toUpperCase()]: { header: "#e42e4d", cell: "#ef97a8" },
      ["3. Styrelsen sammansättning och förutsättningar".toUpperCase()]: { header: "#f1ad4a", cell: "#f8c799" },
      ["4. Styrelsens löpande arbete".toUpperCase()]: { header: "#2856ad", cell: "#7693c7" },
      ["5. VD och Successionsplanering".toUpperCase()]: { header: "#f16c33", cell: "#fcb8aa" },

      NONE1: { header: "#d800f8", cell: "#e071fc" },
      NONE2: { header: "#00ffd0", cell: "#8affe6" },
      NONE3: { header: "#a9fc00", cell: "#d1ff84" },
      UNKNOWN: { header: "#003789", cell: "#4c73ac" },
    },
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setLoadingOverlay(state, payload) {
      state.loadingOverlay = payload;
    },
    setEntries(state, payload) {
      state.entries = payload;
    },
    setSites(state, payload) {
      state.sites = payload;
    },
    setEquipment(state, payload) {
      state.equipments = payload;
    },
    setSelectedQuestionnaireType(state, payload) {
      state.selectedQuestionnaireType = payload;
    },
  },
  actions: {
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : "",
        callback: payload.showButton && payload.callback ? payload.callback : () => {},
      };
    },
    async reloadEntries(context) {
      try {
        const allEntries = await apiCollection.getAllEntries();
        allEntries.map(entry => {
          if (entry.equipments) {
            entry.equipmentIds = entry.equipments.map(e => e.id);
          } else {
            entry.equipmentIds = [];
          }
        });
        context.commit("setEntries", allEntries);
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_loading_entries"),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t("try_again"),
          callback: () => {
            context.dispatch("reloadEntries");
          },
        });
        console.log(error);
      }
    },
    async upsertEntry(context, payload) {
      try {
        const entry = await apiCollection.upsertEntry(payload);
        return entry;
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_updating_entry"),
          showButton: false,
        });
        console.log(error);
      }
      return null;
    },
    async reloadSites(context) {
      try {
        const allSites = await apiCollection.getAllSites();
        context.commit("setSites", allSites);
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_loading_sites"),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t("try_again"),
          callback: () => {
            context.dispatch("reloadSites");
          },
        });
        console.log(error);
      }
    },
    async upsertSite(context, payload) {
      try {
        const response = await apiCollection.upsertSite(payload);
        await context.dispatch("reloadSites");
        return response;
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_updating_site"),
          showButton: false,
        });
        console.log(error);
        return false;
      }
    },
    async reloadEquipments(context) {
      try {
        const allEquipment = await apiCollection.getAllEquipments();
        context.commit("setEquipment", allEquipment);
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_loading_equipment"),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t("try_again"),
          callback: () => {
            context.dispatch("reloadEquipment");
          },
        });
        console.log(error);
      }
    },
    async upsertEquipment(context, payload) {
      try {
        await apiCollection.upsertEquipment(payload);
        await context.dispatch("reloadEquipments");
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_updating_equipment"),
          showButton: false,
        });
        console.log(error);
        return false;
      }
      return true;
    },
    async massUpdateInvoicedStates(context, payload) {
      try {
        const response = await apiCollection.massUpdateInvoiced(payload);
        await context.dispatch("reloadEntries");
        return response;
      } catch (error) {
        context.dispatch("showNotification", {
          color: "error",
          message: i18n.t("errors.error_mass_updating_invoiced_state"),
          showButton: false,
        });
        console.log(error);
        return false;
      }
    },
  },
});
