<template>
  <g>
    <g data-name="Area name" transform="translate(300, 300)">
      <g v-if="star.nameTransform && !star.demonstration" :transform="`translate(${star.nameTransform.x} ${star.nameTransform.y})`">
        <g>
          <text
            v-if="star.areaName"
            :fill="color"
            alignment-baseline="middle"
            class="text"
            dominant-baseline="middle"
            font-size="12"
            text-anchor="middle"
            >{{ star.areaName }}
          </text>
        </g>
      </g>
    </g>
    <g
      :transform="`translate(${star.transform.x}, ${star.transform.y}) rotate(${star.transform.rotate})`"
      style="transform-origin: 50% 50% 0"
    >
      <use href="#Sakara"></use>
      <g v-if="unitType !== 'average'" class="cls-3">
        <template v-for="(percentage, i) in [...star.percentage].sort((a, b) => b.value - a.value)">
          <use
            :key="`${i}`"
            :fill="percentage.color"
            :transform="`translate(0, ${getMaskTranslateY(percentage.value)})`"
            href="#Color_Mask"
          ></use>
        </template>
      </g>
      <g v-if="unitType === 'average'" class="cls-3">
        <template v-for="(average, i) in [...star.average].sort((a, b) => b.value - a.value)">
          <use
            :key="`${i}`"
            :fill="average.color"
            :transform="`translate(0, ${getMaskTranslateY(average.percentageValue)})`"
            href="#Color_Mask"
          ></use>
        </template>
      </g>
      <use href="#Stripes"></use>

      <g v-if="!star.demonstration && unitType === 'average'" data-name="Values" transform="translate(300, 300)">
        <template v-for="(average, i) in star.average">
          <g :key="`text_${i}`" :transform="`${i % 2 === 0 ? 'translate(-55, -136)' : 'translate(90, -136)'}`">
            <g :transform="`translate(${getMaskTranslate(average.percentageValue, i)}) rotate(-${star.transform.rotate})`">
              <!--<rect fill="#fff" height="11" width="27" x="-14" y="-7"></rect>-->
              <text
                :fill="average.color"
                alignment-baseline="middle"
                class="text"
                dominant-baseline="middle"
                font-size="12"
                text-anchor="middle"
              >
                {{ formatNumberAverage(average.value) }}
              </text>
            </g>
          </g>
        </template>
      </g>
      <g v-if="!star.demonstration && unitType !== 'average'" data-name="Values" transform="translate(300, 300)">
        <template v-for="(percentage, i) in star.percentage">
          <g :key="`text_${i}`" :transform="`${i % 2 === 0 ? 'translate(-55, -136)' : 'translate(90, -136)'}`">
            <g :transform="`translate(${getMaskTranslate(percentage.value, i)}) rotate(-${star.transform.rotate})`">
              <!--<rect fill="#fff" height="11" width="27" x="-14" y="-7"></rect>-->
              <text
                :fill="percentage.color"
                alignment-baseline="middle"
                class="text"
                dominant-baseline="middle"
                font-size="12"
                text-anchor="middle"
              >
                {{ formatNumber(percentage.value) }}%
              </text>
            </g>
          </g>
        </template>
      </g>
      <g v-if="!star.demonstration" data-name="Value100" transform="translate(300, 300)">
        <g v-if="unitType === 'percentage'" :transform="`translate(175, 335)`">
          <template v-for="(percentage, i) in star.percentage">
            <g :key="`text_${i}`" :transform="`translate(0,0) rotate(-${star.transform.rotate})`">
              <!--<rect fill="#fff" height="11" width="27" x="-14" y="-7"></rect>-->
              <text alignment-baseline="middle" class="text" dominant-baseline="middle" fill="#d0d0d0" font-size="10" text-anchor="middle">
                100%
              </text>
            </g>
          </template>
        </g>
        <g v-if="unitType === 'average'" :transform="`translate(175, 335)`">
          <template v-for="(average, i) in star.average">
            <g :key="`text_${i}`" :transform="`translate(0,0) rotate(-${star.transform.rotate})`">
              <!--<rect fill="#fff" height="11" width="27" x="-14" y="-7"></rect>-->
              <text alignment-baseline="middle" class="text" dominant-baseline="middle" fill="#d0d0d0" font-size="10" text-anchor="middle">
                {{ star.singleMax }}
              </text>
            </g>
          </template>
        </g>
      </g>
      <g
        v-if="!star.demonstration && star.percentage && star.percentage.length === 2 && unitType !== 'average'"
        data-name="ValueDiff"
        transform="translate(300, 300)"
      >
        <g :transform="'translate(90, -136)'">
          <g :key="`text_value_diff`" :transform="`translate(-71,70) rotate(-${star.transform.rotate})`">
            <circle :stroke="star.percentage[0].color" cx="0" cy="0" fill="#fff" r="22" stroke-width="2"></circle>
            <text
              :fill="star.percentage[0].color"
              alignment-baseline="middle"
              class="text"
              dominant-baseline="middle"
              font-size="13"
              text-anchor="middle"
            >
              {{ diffText }}
            </text>
          </g>
        </g>
      </g>
      <g
        v-if="!star.demonstration && star.average && star.average.length === 2 && unitType === 'average'"
        data-name="ValueDiff"
        transform="translate(300, 300)"
      >
        <g :transform="'translate(90, -136)'">
          <g :key="`text_value_diff`" :transform="`translate(-71,70) rotate(-${star.transform.rotate})`">
            <circle :stroke="star.average[0].color" cx="0" cy="0" fill="#fff" r="22" stroke-width="2"></circle>
            <text
              :fill="star.average[0].color"
              alignment-baseline="middle"
              class="text"
              dominant-baseline="middle"
              font-size="13"
              text-anchor="middle"
            >
              {{ diffText }}
            </text>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  name: 'star',
  props: {
    star: {},
    unitType: {
      type: String,
      default: 'percentage',
    },
  },
  computed: {
    areaColors() {
      return this.$store.state.areaColors;
    },
    color() {
      return this.star?.[this.unitType]?.[0]?.color;
    },
    diffText() {
      if (this.unitType === 'average') {
        const diff = Math.round(this.star.average[1].value) - Math.round(this.star.average[0].value);
        return `${diff > 0 ? '+' : ''}${diff}`;
      }
      const diff = Math.round(this.star.percentage[1].value) - Math.round(this.star.percentage[0].value);
      return `${diff > 0 ? '+' : ''}${diff}%`;
    },
  },

  methods: {
    getMaskTranslateY(value) {
      return 142 - (142 / 100) * value;
    },
    getMaskTranslate(value, i) {
      return `${(47 / 100) * value * (i % 2 === 0 ? 1 : -1)}, ${this.getMaskTranslateY(value)}`;
    },
    formatNumber(value) {
      if (isNaN(value)) return 0;
      return value.toLocaleString('en-US', { maximumFractionDigits: 0 });
    },
    formatNumberAverage(value) {
      if (isNaN(value)) return 0;
      return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
    },
  },
};
</script>
