import axios from 'axios';
import moment from 'moment';

function convertEntryTimes(e) {
  e.entryStartTS = e.entryStart;
  e.entryStart = moment(e.entryStart);
  if (e.entryEnd) {
    e.entryEnd = moment(e.entryEnd);
  }
  return e;
}

const api = {
  /** ENTRIES **/
  getAllEntries: async () => {
    const response = await axios.get('/api/tenant/entry');
    const entries = response.data;
    entries.forEach(e => convertEntryTimes(e));
    return entries;
  },
  upsertEntry: async (entry) => {
    let url = '/api/tenant/entry';
    if (entry.id) {
      url = `${url}/${entry.id}`;
    }

    const entryData = {
      distance: entry.distance,
      entryEnd: entry.entryEnd,
      entryStart: entry.entryStart,
      equipmentIds: entry.equipmentIds,
      info: entry.info,
      siteId: entry.siteId,
      userSubject: entry.userSubject,
      workType: entry.workType,
      containsLunch: entry.containsLunch,
      invoiced: entry.invoiced,
    };

    const response = await axios.post(url, entryData);
    return convertEntryTimes(response.data);
  },
  deleteEntry: async (entryId) => {
    const response = await axios.delete('/api/tenant/entry/' + entryId);
    return convertEntryTimes(response.data);
  },
  getEntryImages: async (entryId) => {
    const response = await axios.get(`/api/tenant/entry/${entryId}/images`);
    return response.data;
  },
  uploadImage: async (entryId, file, imageFilename) => {
    const formData = new FormData();
    formData.append('file', file, imageFilename);

    // const config =  {
    //   onUploadProgress: progressEvent => {
    //     this.imageUploadProgress = progressEvent.loaded / progressEvent.total * 100;
    //   },
    // };
    const response = await axios.post(`/api/tenant/image/${entryId}/entry`, formData/*, config*/);
    return response.data;
  },
  massUpdateInvoiced: async (payload) => {
    const invoiced = payload.invoiced;
    const entryIds = payload.entryIds;
    const response = await axios.post('/api/tenant/entry/massUpdateInvoiced', {
      entryIds,
      invoiced
    });
    return response.data;
  },

  /** IMAGES **/
  deleteImage: async (imageKey) => {
    await axios.delete(`/api/tenant/image/${imageKey}`);
  },

  /** SITES **/
  getAllSites: async () => {
    const response = await axios.get('/api/tenant/site');
    return response.data;
  },
  upsertSite: async (site) => {
    let url = '/api/tenant/site';
    if (site.id) {
      url = `${url}/${site.id}`;
    }
    const response = await axios.post(url, {
      address: site.address,
      hidden: site.hidden,
      name: site.name,
      status: site.status,
    });
    return response.data;
  },

  /** EQUIPMENT **/
  getAllEquipments: async () => {
    const response = await axios.get('/api/tenant/equipment');
    return response.data;
  },
  upsertEquipment: async (equipment) => {
    let url = '/api/tenant/equipment';
    if (equipment.id) {
      url = `${url}/${equipment.id}`;
    }
    const response = await axios.post(url, {
      description: equipment.description,
      status: equipment.status,
    });
    return response.data;
  },

  /** USERS **/
  getCloudUsers: async () => {
    const response = await axios.get('/api/user/list-cloud');
    return response.data;
  },
};

export default api;
