const messages = {
  // English
  en: {
    download_png_image: 'Download PNG image',
    download_svg_image: 'Download SVG image',
    title: 'Culture Due Diligence',
    login: {
      username: 'Username',
      password: 'Password',
      login: 'Login',
      sign_in: 'Sign in',
      login_failed: 'Login failed',
      logout: 'Logout',
      forgot_password: 'Forgot password?',
      reset_password: 'Reset password.',
      by_clicking_you_agree: "By pressing Log in-button you accept Hubble's",
      to_learn_more_about_privacy: "You can read how Hubble collects, uses, shares and protects your personal data in Hubble's",
      terms_of_service: 'terms of service',
      privacy_policy: 'privacy policy',
      send_code: 'Send code to email',
      back_to_sign_in: 'Back to login',
      code: 'Code',
      new_password: 'New password',
      change_password: 'Change your password',
      resend_code: 'Resend the code',
      password_change_required: 'You must provide a new password to use the software',
    },
    start: {
      title: 'Start',
    },
    user_management: {
      title: 'User management',
      search: 'Search users',
      roles: 'Roles',
      user: 'User',
      add_role_to_user: 'Add role to user',
      manage_user: 'Manage user',
      manage_user_roles: 'Manager user roles',
      disable: 'Disable user',
      enable: 'Enable user',
      you_cannot_disable_yourself: "You can't remove your own user.",
      you_cannot_enable_yourself: "You can't set your own user to active.",
      user_state_set_to_enabled: 'User set to active.',
      user_state_set_to_disabled: 'User set to disabled.',
      user_state_change_failed: 'User state change failed. Please try again.',
      user_roles_updated: 'User roles updated',
      user_roles_update_failed: 'Updating user roles failed. Please try again.',
      create_new_user: 'Add new user',
      user_type: 'User type',
      shared_user: 'Shared user',
      shared_user_hint: 'Select this when it is a device acccount which multiple person uses.',
      user_info: 'User info',
      user_roles: 'User roles',
      machine_id: 'Machine id',
      email: 'Email',
      password: 'Password',
      first_name: 'Firstname',
      last_name: 'Lastname',
      phone: 'Phone',
      user_created_success: 'User created successfully.',
      user_creation_failed: 'Creating user failed. Please try again',
      no_username_or_email_given: 'Creating user failed because username or email was empty.',
      user_exists_with_username: 'Username or email already exists.',
      no_password_for_agent_user_given: "You didn't provide a password for device agent.",
      constraint_exception: {
        roleSet: 'You must give at least one role to user.',
      },
      filter: 'Filter',
      filtered: 'Filtered',
      show_all: 'Show all',
      selections: 'selections',
      role: {
        admin: 'System administrator',
        basic: 'System basic user',
        agent: 'Device agent',
        tenant_admin: 'Admin',
        tenant_basic: 'Basic',
      },
      user_settings: 'Settings',
      user_info_updated: 'User info updated',
      user_info_update_failed: 'Updating user info failed. Please try again.',
      edit_user_settings: 'Edit user settings',
    },
    tenants: {
      upload_logo: 'Upload logo',
      title: 'Customers',
      add_new_tenant: 'Add new customer',
      add_new_user: 'Add new user',
      organization_name: 'Customer name',
      organization: 'Customer',
      tenant_created_success: 'New customer created',
      tenant_creation_failed: 'Creating a new customer failed. Please try again. ',
      tenant_update_failed: 'Updating customer failed. Please try again.',
      tenant_update_success: 'Customer updated.',
      organization_update_failed: 'Customer update failed. Please try again.',
      organization_update_success: 'Customer updated',
      search: 'Search customers',
      status: 'Status',
      settings: 'Settings',
      users: 'Users',
      tenant_users: 'Users attached to customer',
      manage_tenant: 'Manage customer',
      enable: 'Set as active',
      disable: 'Set as inactive',
      edit_tenant: 'Edit customer',
      edit_organization: 'Edit customer',
      no_tenants: 'No customer',
      no_tenants_help: "You don't belong to any customer. Please try again or contact your administrator.",
      statuses: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
      },
      choose_organization: 'Select customer',
      user_add_success: 'User added successfully.',
      user_add_fail: 'User add failed. Please try again.',
      delete_user: 'Remove user from customer',
      user_delete_success: 'User removed from customer',
      user_delete_fail: 'Removeing user from customer failed. Please try again.',
      management_title: 'Customer management',
      organization_users: 'Customer users',
      all_users_for_app: 'All users',
      no_users_in_app: 'There were no users.',
      no_users_in_tenant: 'There were no users attached to customer.',
    },
    user_profile: {
      title: 'Own profile',
      user_account: 'User account',
      push_notifications: 'Push notifications',
      push_notifications_explained: 'By allowing push notifications the app can send you notifications without the app open.',
      allow_notifications: 'Allow notifications',
      change_password: 'Change password',
      old_password: 'Old password',
      new_password: 'New password',
      new_password_again: 'New password again',
      new_password_guide: 'Select a strong password that is not used elsewhere',
      new_passwords_has_to_match: 'Passwords have to match',
      password_must_be_at_least_6_characters: 'Password must be at leat 6 characters.',
      errors: {
        new_passwords_do_not_match: 'Password did not match.',
        old_password_must_be_at_least_6_characters: 'You current password is wrong.',
        new_password_must_be_at_least_6_characters: 'New password must be at least 6 characters.',
        NotAuthorizedException: 'Your current password is wrong.',
        InvalidParameterException: 'Your passwords are incorrect.',
        InvalidPasswordException:
          'Your password must be at least 6 characters. Password needs to include uppercase and lowercase letters and at least one number and one special character.',
        LimitExceededException: 'You have tried to change password too often. Please try again later.',
      },
      change_image: 'Change image',
      edit_profile: 'Edit profile',
    },
    validation: {
      field_is_required: 'Field is required',
      email_is_required: 'Field must contain a valid email address',
      spaces_are_not_allowed: 'Spaces are not allowed',
      check_all_required_fields: 'Check all required fields',
    },
    errors: {
      error_loading_users: 'Loading users failed. Please try again.',
    },
    menu_subheaders: {
      organization_admin: 'Customer admin',
      application_admin: 'Application admin',
    },

    cancel: 'Cancel',
    search: 'Search',
    try_again: 'Try again',
    update_available: 'Update available',
    install_update: 'Install',
    generic_error: 'An error occurred.',
    add: 'Add',
    reload: 'Reload',
    edit: 'Edit',
    remove: 'Remove',
    tags: 'Tags',
    create_new: 'Create new',
    show_details: 'Show details',
    hide_details: 'Hide details',
    previous: 'Previous',
    next: 'Next',
    done: 'Done',
    send: 'Send',
    change_image: 'Change image',
    add_image: 'Add image',
    delete_image: 'Delete image',
    set_active: 'Set as active',
    set_inactive: 'Set as inactive',

    ok: 'Ok',
    no: 'No',
    yes: 'Yes',
    delete: 'Delete',
    questionnaire: {
      _s: 'Evaluations',
      _: 'Evaluation',

      import: {
        link: 'Import Questions',
        title: 'Import Questions from Excel',
        answerers_link: 'Import Answerers',
        answerers_title: 'Import Answerers from Excel',
      },
      download_report: 'Download answer report',
      download_report_include_own_answers: 'Download answer report with test answers',
      share_report_link: 'Get report share link',
      share_report_link2: 'Get report share link v2',
    },
    question: {
      _: 'Question',
      code: 'Code',
      order: 'Order',
      answerType: 'Answer type',
    },
    area: {
      _: 'Area',
    },
    dimension: {
      _: 'Sub dimension',
      parent: 'Main dimension',
    },
    answerer: {
      _: 'Answerer',
      _s: 'Answerers',
      name: 'Name',
      email: 'Email',
      uuid: 'UUID',
      link: 'Link',
      send_link: 'Send link',
    },
    close: 'Close',
    save: 'Save',
    import: 'Import',
    message: {
      import: {
        successful: 'Import successful',
      },
    },
    highest: 'Highest',
    lowest: 'Lowest',
    gap: 'Gap',
  },
  // Finnish
  fi: {
    download_png_image: 'Lataa PNG kuva',
    download_svg_image: 'Lataa SVG kuva',
    title: 'Culture Due Diligence',
    login: {
      username: 'Käyttäjätunnus',
      password: 'Salasana',
      login: 'Kirjaudu',
      sign_in: 'Kirjaudu sisään',
      login_failed: 'Kirjautuminen epäonnistui',
      logout: 'Kirjaudu ulos',
      forgot_password: 'Unohditko salasanasi?',
      reset_password: 'Nollaa salasanasi.',
      by_clicking_you_agree: 'Painamalla "Kirjaudu sisään"-painiketta hyväksyt Hubblen',
      to_learn_more_about_privacy: 'Voit lukea kuinka Hubble kerää, käyttää, jakaa ja suojelee henkilökohtaisia tietojasi Hubblen',
      terms_of_service: 'käyttöehdot',
      privacy_policy: 'tietosuojakäytännöstä',
      send_code: 'Lähetä koodi sähköpostiin',
      back_to_sign_in: 'Takaisin kirjautumiseen',
      code: 'Koodi',
      new_password: 'Uusi salasana',
      change_password: 'Vaihda salasanasi',
      resend_code: 'Lähetä koodi uudelleen',
      password_change_required: 'Sinun tulee antaa uusi salasana jatkaaksesi sovelluksen käyttöä.',
    },
    start: {
      title: 'Aloitus',
    },
    user_management: {
      title: 'Käyttäjien hallinta',
      search: 'Hae käyttäjistä',
      roles: 'Roolit',
      user: 'Käyttäjä',
      add_role_to_user: 'Lisää käyttäjälle rooli',
      manage_user: 'Hallitse käyttäjää',
      manage_user_roles: 'Hallitse käyttäjän rooleja',
      disable: 'Poista käyttäjä käytöstä',
      enable: 'Aseta käyttäjä aktiiviseksi',
      you_cannot_disable_yourself: 'Et voi poistaa omaa tunnustasi käytöstä.',
      you_cannot_enable_yourself: 'Et voi asettaa omaa tunnustasi aktiiviseksi.',
      user_state_set_to_enabled: 'Käyttäjä asetettu aktiiviseksi.',
      user_state_set_to_disabled: 'Käyttäjä poistettu käytöstä.',
      user_state_change_failed: 'Käyttäjän tilan vaihtaminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      user_roles_updated: 'Käyttäjän roolit päivitetty.',
      user_roles_update_failed: 'Käyttäjän roolien päivittäminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      create_new_user: 'Luo uusi käyttäjä',
      user_type: 'Tilin tyyppi',
      shared_user: 'Jaettu käyttäjä',
      shared_user_hint: 'Valitse tämä kun kyseessä on laitekohtainen tili, jota käyttää useampi henkilö',
      user_info: 'Käyttäjän tiedot',
      user_roles: 'Käyttäjän roolit',
      machine_id: 'Laitetunniste',
      email: 'Sähköposti',
      password: 'Salasana',
      first_name: 'Etunimi',
      last_name: 'Sukunimi',
      phone: 'Puhelinnumero',
      user_created_success: 'Käyttäjä on luotu onnistuneesti.',
      user_creation_failed: 'Käyttäjän luominen epäonnistui. Ole hyvä ja yritä uudelleen.',
      no_username_or_email_given: 'Käyttäjälle luominen epäonnistui, koska käyttäjälle ei annettu sähköpostia tai käyttäjänimeä.',
      user_exists_with_username: 'Antamallasi käyttäjänimellä tai sähköpostiosoitteella on jo käyttäjä olemassa.',
      no_password_for_agent_user_given: 'Et määrittänyt salasanaa jaetulle käyttäjälle.',
      constraint_exception: {
        roleSet: 'Käyttäjälle tulee määritellä ainakin yksi rooli.',
      },
      filter: 'Suodata',
      filtered: 'Suodatettu',
      show_all: 'Näytetään kaikki',
      selections: 'valintaa',
      role: {
        admin: 'Järjestelmän ylläpitäjä',
        basic: 'Järjestelmän Peruskäyttäjä',
        agent: 'Laitekäyttäjä',
        tenant_admin: 'Ylläpitäjä',
        tenant_basic: 'Peruskäyttäjä',
      },
      user_settings: 'Asetukset',
      user_info_updated: 'Käyttäjän tiedot päivitetty',
      user_info_update_failed: 'Käyttäjän tietojen päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      edit_user_settings: 'Muokkaa käyttäjän tietoja',
    },
    tenants: {
      title: 'Asiakkuudet',
      add_new_tenant: 'Lisää uusi asiakkuus',
      add_new_user: 'Lisää uusi käyttäjä',
      organization_name: 'Organisaation nimi',
      organization: 'Organisaatio',
      tenant_created_success: 'Uusi asiakkuus luotu',
      tenant_creation_failed: 'Uuden asiakkuuden luominen ei onnistunut. Ole hyvä ja yritä uudelleen',
      tenant_update_failed: 'Asiakkuuden päivitys ei onnistunut. Ole hyvä ja yritä uudelleen.',
      tenant_update_success: 'Asiakkuus päivitetty.',
      organization_update_failed: 'Organisaation tietojen päivitys ei onnistunut. Ole hyvä ja yritä uudelleen.',
      organization_update_success: 'Organisaation tiedot päivitetty.',
      search: 'Hae asiakkuuksista',
      status: 'Tila',
      settings: 'Asetukset',
      users: 'Käyttäjät',
      tenant_users: 'Asiakkuuteen linkitetyt käyttäjät',
      manage_tenant: 'Hallitse asiakkuutta',
      enable: 'Aseta aktiiviseksi',
      disable: 'Poista käytöstä',
      edit_tenant: 'Muokkaa asiakkuutta',
      edit_organization: 'Muokkaa organisaatiota',
      no_tenants: 'Ei organisaatiota',
      no_tenants_help:
        'Et kuulu yhteenkään organisaatioon. Ole hyvä yritä uudelleen. Jos sivun uudelleen lataaminen ei auta, niin ota yhteys organisaatiosi ylläpitäjään.',
      statuses: {
        ACTIVE: 'Aktiivinen',
        INACTIVE: 'Inaktiivinen',
      },
      choose_organization: 'Valitse organisaatio',
      user_add_success: 'Käyttäjän lisätty onnistuneesti.',
      user_add_fail: 'Käyttäjän lisääminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      delete_user: 'Poista käyttäjä',
      user_delete_success: 'Käyttäjä poistettu organisaatiolta onnistuneesti.',
      user_delete_fail: 'Käyttäjän poistaminen organisaatiolta epäonnistui. Ole hyvä ja yritä uudelleen.',
      management_title: 'Organisaation hallinta',
      organization_users: 'Organisaation käyttäjät',
      all_users_for_app: 'Kaikki sovelluksen käyttäjät',
      no_users_in_app: 'Sovelluksesta ei löytynyt yhtään käyttäjää.',
      no_users_in_tenant: 'Asiakkuudesta ei löytynyt yhtään käyttäjää.',
    },
    user_profile: {
      title: 'Omat asetukset',
      user_account: 'Käyttäjätili',
      push_notifications: 'Push-ilmoitukset',
      push_notifications_explained:
        'Sallimalla push-ilmoitukset sovellus voi lähettää sinulle ilmoituksia vaikka sovellus olisi pois päältä.',
      allow_notifications: 'Salli ilmoitukset',
      change_password: 'Vaihda salasana',
      old_password: 'Nykyinen salasanasi',
      new_password: 'Uusi salasanasi',
      new_password_again: 'Uusi salasanasi uudelleen',
      new_password_guide: 'Valitse vahva salasana, joka ei ole käytössä muualla.',
      new_passwords_has_to_match: 'Tämän tulee olla sama kuin uusi salasanasi',
      password_must_be_at_least_6_characters: 'Salasanan tulee olla vähintään 6 merkkiä pitkä.',
      errors: {
        new_passwords_do_not_match: 'Uusi salasanasi ja uudelleen kirjoitettu uusi salasanasi eivät vastaa toisiaan.',
        old_password_must_be_at_least_6_characters: 'Antamasi nykyinen salasanasi on väärin.',
        new_password_must_be_at_least_6_characters: 'Uusi salasanasi tulee olla vähintään 6 merkkiä pitkä.',
        NotAuthorizedException: 'Antamasi nykyinen salasana on väärin.',
        InvalidParameterException: 'Antamasi salasanat ovat virheellisiä.',
        InvalidPasswordException:
          'Uusi salasanasi pitää olla vähintään 6 merkkiä pitkä. Salasanan tulee sisältää isoja sekä pieniä kirjaimia, vähintään yksi numero ja vähintään yksi erikoismerkki.',
        LimitExceededException:
          'Olet yrittänyt vaihtaa salasanan liian monta kertaa lyhyessä ajassa. Ole hyvä ja odota hetki ennen kuin yrität uudelleen.',
      },
      change_image: 'Vaihda kuva',
      edit_profile: 'Muokkaa tietoja',
    },
    validation: {
      field_is_required: 'Kenttä on pakollinen',
      email_is_required: 'Kentän tulee olla sähköpostiosoite',
      spaces_are_not_allowed: 'Välilyönnit eivät ole sallittuja',
      check_all_required_fields: 'Tarkista, että olet täyttänyt kaikki pakolliset kentät',
    },
    errors: {
      error_loading_users: 'Käyttäjien hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
    },
    menu_subheaders: {
      organization_admin: 'Organisaation ylläpito',
      application_admin: 'Sovelluksen ylläpito',
    },

    cancel: 'Peruuta',
    save: 'Tallenna',
    search: 'Hae',
    try_again: 'Yritä uudelleen',
    update_available: 'Päivitys saatavilla',
    install_update: 'Asenna',
    generic_error: 'Tapahtui virhe.',
    add: 'Lisää',
    reload: 'Lataa uudelleen',
    edit: 'Muokkaa',
    remove: 'Poista',
    tags: 'Tägit',
    yes: 'Kyllä',
    no: 'Ei',
    ok: 'Ok',
    create_new: 'Luo uusi',
    show_details: 'Näytä tarkemmat tiedot',
    hide_details: 'Piilota tarkemmat tiedot',
    previous: 'Edellinen',
    next: 'Seuraava',
    done: 'Valmis',
    send: 'Lähetä',
    change_image: 'Vaihda kuva',
    add_image: 'Lisää kuva',
    delete_image: 'Poista kuva',
    set_active: 'Aseta aktiiviseksi',
    set_inactive: 'Poista käytöstä',
    highest: 'Korkein',
    lowest: 'Alhaisin',
    gap: 'Väli',
  },
  // German
  de: {},
  // Swedish
  sv: {
    highest: 'Högsta',
    lowest: 'Lägst',
    gap: 'Mellanliggande',
  },
};

export default messages;
