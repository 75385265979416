const questionnairelangs = (questionnaire) => [
  {
    value: 'En',
    flag: 'us',
    custom: questionnaire.langEnName?.toLowerCase() !== 'english',
    label: questionnaire.langEnName || 'English',
  },
  {
    value: 'Sv',
    flag: 'se',
    custom: questionnaire.langSvName?.toLowerCase() !== 'svenska',
    label: questionnaire.langSvName || 'Svenska',
  },
  {
    value: 'Fi',
    flag: 'fi',
    custom: questionnaire.langFiName?.toLowerCase() !== 'suomi',
    label: questionnaire.langFiName || 'Suomi',
  },
  {
    value: 'Da',
    flag: 'da',
    custom: questionnaire.langDaName?.toLowerCase() !== 'dansk',
    label: questionnaire.langDaName || 'Dansk',
  },
  {
    value: 'No',
    flag: 'no', // Replace with the appropriate flag code
    custom: questionnaire.langNoName?.toLowerCase() !== 'norsk',
    label: questionnaire.langNoName || 'Norsk',
  },
  {
    value: 'De',
    flag: 'de', // Replace with the appropriate flag code
    custom: questionnaire.langDeName?.toLowerCase() !== 'deutsch',
    label: questionnaire.langDeName || 'Deutsch',
  },
  {
    value: 'Fr',
    flag: 'fr', // Replace with the appropriate flag code
    custom: questionnaire.langFrName?.toLowerCase() !== 'français',
    label: questionnaire.langFrName || 'Français',
  },
  {
    value: 'Pt',
    flag: 'pt', // Replace with the appropriate flag code
    custom: questionnaire.langPtName?.toLowerCase() !== 'português',
    label: questionnaire.langPtName || 'Português',
  },
  {
    value: 'Es',
    flag: 'es', // Replace with the appropriate flag code
    custom: questionnaire.langEsName?.toLowerCase() !== 'español',
    label: questionnaire.langEsName || 'Español',
  },
  {
    value: 'Br',
    flag: 'br', // Replace with the appropriate flag code
    custom: questionnaire.langBrName?.toLowerCase() !== 'português do brasil',
    label: questionnaire.langBrName || 'Português do Brasil',
  },
  {
    value: 'Pl',
    flag: 'pl', // Replace with the appropriate flag code
    custom: questionnaire.langPlName?.toLowerCase() !== 'polski',
    label: questionnaire.langPlName || 'Polski',
  },
  {
    value: 'Zh',
    flag: 'zh', // Replace with the appropriate flag code
    custom: questionnaire.langZhName?.toLowerCase() !== "zh",
    label: questionnaire.langZhName || 'Zh',
  },
  {
    value: 'Ko',
    flag: 'ko', // Replace with the appropriate flag code
    custom: questionnaire.langKoName?.toLowerCase() !== 'ko',
    label: questionnaire.langKoName || 'Ko',
  },
  {
    value: 'Ja',
    flag: 'ja', // Replace with the appropriate flag code
    custom: questionnaire.langJaName?.toLowerCase() !== 'ja ',
    label: questionnaire.langJaName || 'Ja',
  },


];

export default questionnairelangs;
